import isElement from 'lodash/isElement';
import { Tooltip, Popover } from 'bootstrap';

const NewEntryNotifier = (polled_selector, notification_sound_name) => {
  const list_node = document.querySelector(polled_selector);
  const notification_sound = require(`../${notification_sound_name}.mp3`);

  const sound = new Howl({
    src: [notification_sound],
    volume: 0.25,
    onplayerror: () => {
      sound.once('unlock', () => {
        sound.play();
      });
    },
  });

  const callback = mutationList => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        processNewMessage(mutation.addedNodes);
      }
    }
  };

  function processNewMessage(nodes) {
    Array.from(nodes).map(node => {
      try {
        if (isElement(node)) {
          const _node = $(node);
          _node.addClass('card-highlight');

          setTimeout(() => {
            _node.removeClass('card-highlight');
          }, 500);

          sound.play();

          initPricesPlaceholder(_node);
          const tooltipTriggerList = _node.find('[data-toggle=tooltip]');
          const tooltipList = [...tooltipTriggerList].map(
            tooltipTriggerEl => new Tooltip(tooltipTriggerEl, { html: true })
          );

          var popoverTriggerList = [].slice.call(
            node.querySelectorAll('[data-bs-toggle="popover"]')
          );
          var popoverList = popoverTriggerList.map(
            popoverTriggerEl => new Popover(popoverTriggerEl, { html: true })
          );
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  return () => {
    const observer = new MutationObserver(callback);

    if (list_node) {
      observer.observe(list_node, {
        attributes: false,
        childList: true,
        subtree: false,
      });
    }
  };
};

export default NewEntryNotifier;
