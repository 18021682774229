import consumer from './consumer';
import { Howl, Howler } from 'howler';
const notification_sound = require('../option_notification.mp3');

const sound = new Howl({
  src: [notification_sound],
  volume: 0.25,
});

let page_filters = {};

const entry_in_filter = (entry, queries) => {
  if (
    queries.has('[search][tickers]') &&
    queries.get('[search][tickers]') !== ''
  ) {
    const tickers = queries.get('[search][tickers]').toUpperCase().split(',');

    if (!tickers.includes(entry.ticker.toUpperCase())) {
      console.debug('Ticker filters not met');
      return false;
    }
  }

  if (
    queries.has('[filters][order_type][]') &&
    queries.getAll('[filters][order_type][]') !== []
  ) {
    const filters = queries.getAll('[filters][order_type][]');

    if (!filters.includes(entry.order_type)) {
      console.debug('Options order type filters not met');
      return false;
    }
  }

  if (
    queries.has('[filters][tickers.issue_type][]') &&
    queries.getAll('[filters][tickers.issue_type][]') !== []
  ) {
    const filters = queries.getAll('[filters][tickers.issue_type][]');

    if (!filters.includes(entry.ticker_issue_type)) {
      console.debug('Ticker issue type filters not met');
      return false;
    }
  }

  if (
    queries.has('[filters][direction][]') &&
    queries.getAll('[filters][direction][]') !== []
  ) {
    const filters = queries.getAll('[filters][direction][]');

    if (!filters.includes(entry.direction)) {
      console.debug('Option direction filters not met');
      return false;
    }
  }

  if (
    queries.has('[search][active_status][]') &&
    queries.getAll('[search][active_status][]') !== []
  ) {
    const filters = queries.getAll('[search][active_status][]');
    let status_code = 'not_expired';
    if (entry.is_expired) {
      status_code = 'expired';
    }

    if (!filters.includes(status_code)) {
      console.debug('Option status filters not met');
      return false;
    }
  }

  if (
    queries.has('[filters][sweep]') &&
    queries.get('[filters][sweep]') !== ''
  ) {
    let is_sweep_only = queries.getAll('[filters][sweep]');
    is_sweep_only = is_sweep_only[is_sweep_only.length - 1] == '1';

    if (is_sweep_only && !entry.sweep) {
      console.debug('Option sweep filters not met');
      return false;
    }
  }

  if (
    queries.has('[filters][min_strike_days_delta]') &&
    queries.get('[filters][min_strike_days_delta]') !== ''
  ) {
    let filter = parseInt(queries.get('[filters][min_strike_days_delta]'));

    if (filter > entry.days_to_expiration) {
      console.debug('Options min days to expiration filters not met');
      return false;
    }
  }

  if (
    queries.has('[filters][max_strike_days_delta]') &&
    queries.get('[filters][max_strike_days_delta]') !== ''
  ) {
    let filter = parseInt(queries.get('[filters][max_strike_days_delta]'));

    if (filter < entry.days_to_expiration) {
      console.debug('Options max days to expiration filters not met');
      return false;
    }
  }

  if (
    queries.has('[filters][min_options_cost]') &&
    queries.get('[filters][min_options_cost]') !== ''
  ) {
    let filter = parseFloat(queries.get('[filters][min_options_cost]'));

    if (filter > entry.options_cost) {
      console.debug('Options min option cost filters not met');
      return false;
    }
  }

  if (
    queries.has('[filters][max_options_cost]') &&
    queries.get('[filters][max_options_cost]') !== ''
  ) {
    let filter = parseFloat(queries.get('[filters][max_options_cost]'));

    if (filter < entry.options_cost) {
      console.debug('Options max option cost filters not met');
      return false;
    }
  }

  if (
    queries.has('[filters][min_target]') &&
    queries.get('[filters][min_target]') !== ''
  ) {
    let filter = parseFloat(queries.get('[filters][min_target]'));

    if (filter > entry.target_performance) {
      console.debug('Options min target filters not met');
      return false;
    }
  }

  if (
    queries.has('[filters][max_target]') &&
    queries.get('[filters][max_target]') !== ''
  ) {
    let filter = parseFloat(queries.get('[filters][max_target]'));

    if (filter < entry.target_performance) {
      console.debug('Options max target filters not met');
      return false;
    }
  }

  return true;
};

consumer.subscriptions.create('EntriesChannel', {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (window.location.pathname == '/options') {
      window.optionsPageFilters ||= $('#filters-form').serialize();

      const node = $(data.body);
      const entry = JSON.parse(data.entry);
      const queries = new URLSearchParams(window.optionsPageFilters);

      if (entry_in_filter(entry, queries)) {
        if ($('#' + node.attr('id')).length >= 1) {
          // Replace current node
          $('#entries-table #' + node.attr('id')).html(node.html());
        } else {
          // Prepend new node
          $('#entries-table').prepend(node);
          sound.play();
        }

        const new_node = $('#' + node.attr('id'));

        initPricesPlaceholder(new_node);
        initPopper();
        new_node.find('[data-bs-toggle=tooltip]').tooltip({ html: true });

        new_node.addClass('tr-highlight');

        setTimeout(() => {
          $('.tr-highlight').removeClass('tr-highlight');
        }, 500);
      }
    }
  },
});
